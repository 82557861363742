// adminReducer.js
const initialState = {
  onCreateLicenseLoading: false,
  creationError: null,
  isLoading: false,
  list: [],
  error: null,
  onDeleteLicenseLoading: false,
  licenseTab: "1"
};

export const licenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_LICENSE_KEY":
      return {
        ...state,
        onCreateLicenseLoading: true,
      };
    case "CREATE_LICENSE_KEY_SUCCESS":
      return {
        ...state,
        onCreateLicenseLoading: false,
        creationError: null,
      };
    case "CREATE_LICENSE_KEY_FAILURE":
      return {
        ...state,
        onCreateLicenseLoading: false,
        creationError: action.payload,
      };

    case "FETCH_LICENSE_KEYS":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_LICENSE_KEYS_SUCCESS":
      return {
        ...state,
        list: action.payload,
        isLoading: false,
        error: null,
      };
    case "FETCH_LICENSE_KEYS_FAILURE":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case "UPDATE_LICENSE_KEY":
      return {
        ...state,
        onCreateLicenseLoading: true,
      };

    case "UPDATE_LICENSE_KEY_SUCCESS":
      return {
        ...state,
        onCreateLicenseLoading: false,
        creationError: null,
      };
    case "UPDATE_LICENSE_KEY_FAILURE":
      return {
        ...state,
        onCreateLicenseLoading: false,
        creationError: action.payload,
      };

    case "DELETE_LICENSE_KEY":
      return {
        ...state,
        onDeleteLicenseLoading: true,
      };

    case "DELETE_LICENSE_KEY_SUCCESS":
      return {
        ...state,
        onDeleteLicenseLoading: false,
        creationError: null,
      };
    case "DELETE_LICENSE_KEY_FAILURE":
      return {
        ...state,
        onDeleteLicenseLoading: false,
        creationError: action.payload,
      };
      case "SET_LICENSE_TAB":
      return {
        ...state,
        licenseTab: action.tab,
      };

    default:
      return state;
  }
};
